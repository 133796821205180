import React, { Component } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import Select from "react-select";
import CallApi from "./CallApi";

function PriceList(props) {
  const { Options } = props;
  const selectedOption = Options.find(
    ({ value }) => props.PricelistId === value,
  );

  const handleSelectChange = (value) => {
    props.onChange(value);
  };

  return (
    <>
      <p>Prislista</p>
      <Select
        value={selectedOption}
        options={Options}
        onChange={handleSelectChange}
        isSearchable
      />
    </>
  );
}
//Refactor this to a functional component instead
class Profile extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      Förnamn: "",
      Efternamn: "",
      Kundnr: "",
      Epost: "",
      Telefon: "",
      Adress: "",
      Postnummer: "",
      Postort: "",
      Clearingnr: "",
      Kontonummer: "",
      Kundtyp: "",
      Villkor: false,
      Nyhetsbrev: false,
      id: "",
      result: "",
      PricelistId: "",
      Pricelist: {},
      Pricelists: [],
      Options: [],
      Admin: false,
    };
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "Kundtyp") {
      //Sätt prislista till default eller default företag
      const PricelistId = value === "Privatperson" ? 1 : 2;
      this.setState({
        [name]: value,
        PricelistId,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handlePricelistChange = (event) => {
    this.setState({ PricelistId: event.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.onFormSubmit(this.state);
    this.setState(this.initialState);
  };

  toggle(event) {
    const item = event.target.name;
    const change = !this.state[item];
    this.setState({ [item]: change });
  }

  async componentDidMount() {
    this._isMounted = true;
    let customerId = undefined;
    let state = this.props.location.state;
    if (state !== undefined) {
      customerId = state.id;
    }
    if (customerId !== undefined) {
      await this.loadUserProfile(customerId);
    } else {
      await this.loadUserProfile();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadUserProfile = async (id) => {
    if (id === undefined) {
      if (this._isMounted) {
        id = this.props.auth.user.sub.replace("auth0|", "");
        this.getKundInfo(id);
        this.getPriceLists();
      }
    } else {
      await this.getKundInfo(id);
      await this.getPriceLists();
    }
  };
  async getKundInfo(id) {
    const accessToken = await this.props.auth.getAccessTokenSilently();
    const response = await CallApi("Get", `/kund/${id}`, "", accessToken);
    const { kund } = response.data;
    const {
      Förnamn,
      Efternamn,
      Kundnr,
      Epost,
      Telefon,
      Adress,
      Postnummer,
      Postort,
      Kundtyp,
      Villkor,
      Nyhetsbrev,
      PricelistId,
      DoljForsaljningspris,
      Organisationsnummer,
      Företagsnamn,
      Kontaktperson,
    } = kund;

    this.setState({
      id: kund.id,
      Förnamn: Förnamn ?? "",
      Efternamn: Efternamn ?? "",
      Kundnr: Kundnr ?? "",
      Epost: Epost ?? "",
      Telefon: Telefon ?? "",
      Adress: Adress ?? "",
      Postnummer: Postnummer ?? "",
      Postort: Postort ?? "",
      Kundtyp: Kundtyp ?? "",
      Villkor: Villkor ?? true,
      Nyhetsbrev: Nyhetsbrev ?? true,
      PricelistId: PricelistId ?? "",
      DoljForsaljningspris,
      Organisationsnummer: Organisationsnummer ?? "",
      Företagsnamn: Företagsnamn ?? "",
      Kontaktperson: Kontaktperson ?? "",
      Admin: this.props.auth.roles.includes("Admin"),
    });
  }
  async getPriceLists() {
    const accessToken = await this.props.auth.getAccessTokenSilently();
    const response = await CallApi("Get", `/pricelist`, "", accessToken);
    const { Pricelists } = response.data;
    const Options = Pricelists.map(({ Name, id }) => ({
      label: Name,
      value: id,
      key: id,
    }));
    this.setState({ Pricelists, Options });
  }

  render() {
    const { Kundtyp, Kundnr, Admin } = this.state;
    return (
      <React.Fragment>
        <div className="call-to-action">
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col>
                <h2> Redigera uppgifter</h2>
                <h3>Inlämningsnummer: {Kundnr}</h3>
                <br />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Form.Group controlId="Privatperson">
                  <p>Typ av kund</p>
                  <Form.Check
                    className="CheckBox"
                    type="radio"
                    label="Privatperson"
                    name="Kundtyp"
                    value="Privatperson"
                    onChange={this.handleChange}
                    checked={Kundtyp === "Privatperson"}
                    inline
                    disabled={Kundtyp !== "" && !Admin}
                  />
                  <Form.Check
                    className="CheckBox"
                    type="radio"
                    label="Företag"
                    name="Kundtyp"
                    value="Företag"
                    onChange={this.handleChange}
                    checked={Kundtyp === "Företag"}
                    inline
                    disabled={Kundtyp !== "" && !Admin}
                  />
                  {this.state.Admin && (
                    <Form.Check
                      className="CheckBox"
                      type="checkBox"
                      label="Dölj försäljningspris"
                      name="DoljForsaljningspris"
                      value="DoljForsaljningspris"
                      onChange={this.toggle.bind(this)}
                      checked={this.state.DoljForsaljningspris}
                      inline
                    />
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                {this.state.Admin ? (
                  <Form.Group controlId="Prislista">
                    <PriceList
                      {...this.state}
                      onChange={(event) => this.handlePricelistChange(event)}
                    />
                  </Form.Group>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Form.Group controlId="Epost">
                  <Form.Label>Epost</Form.Label>
                  <Form.Control
                    type="text"
                    name="Epost"
                    value={this.state.Epost}
                    onChange={this.handleChange}
                    placeholder="Epost"
                    disabled={this.state.Admin ? false : true}
                    pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                  />
                </Form.Group>
                {Kundtyp === "Privatperson" && (
                  <>
                    <Form.Group controlId="Förnamn">
                      <Form.Label>Förnamn</Form.Label>
                      <Form.Control
                        type="text"
                        name="Förnamn"
                        value={this.state.Förnamn}
                        onChange={this.handleChange}
                        placeholder="Förnamn"
                      />
                    </Form.Group>
                    <Form.Group controlId="Efternamn">
                      <Form.Label>Efternamn</Form.Label>
                      <Form.Control
                        type="text"
                        name="Efternamn"
                        value={this.state.Efternamn}
                        onChange={this.handleChange}
                        placeholder="Efternamn"
                      />
                    </Form.Group>
                  </>
                )}
                {Kundtyp === "Företag" && (
                  <>
                    <Form.Group controlId="Organisationsnummer">
                      <Form.Label>Organisationsnummer</Form.Label>
                      <Form.Control
                        type="text"
                        name="Organisationsnummer"
                        value={this.state.Organisationsnummer}
                        onChange={this.handleChange}
                        placeholder="Organisationsnummer"
                      />
                    </Form.Group>
                    <Form.Group controlId="Företagsnamn">
                      <Form.Label>Företagsnamn</Form.Label>
                      <Form.Control
                        type="text"
                        name="Företagsnamn"
                        value={this.state.Företagsnamn}
                        onChange={this.handleChange}
                        placeholder="Företagsnamn"
                      />
                    </Form.Group>
                    <Form.Group controlId="Kontaktperson">
                      <Form.Label>Kontaktperson</Form.Label>
                      <Form.Control
                        type="text"
                        name="Kontaktperson"
                        value={this.state.Kontaktperson}
                        onChange={this.handleChange}
                        placeholder="Kontaktperson"
                      />
                    </Form.Group>
                  </>
                )}
                <Form.Group controlId="Telefon">
                  <Form.Label>Telefon</Form.Label>
                  <Form.Control
                    type="text"
                    name="Telefon"
                    value={this.state.Telefon}
                    onChange={this.handleChange}
                    placeholder="Telefon"
                  />
                </Form.Group>
                <Form.Group controlId="Adress">
                  <Form.Label>Adress</Form.Label>
                  <Form.Control
                    type="text"
                    name="Adress"
                    value={this.state.Adress}
                    onChange={this.handleChange}
                    placeholder="Adress"
                  />
                </Form.Group>
                <Form.Group controlId="Postnummer">
                  <Form.Label>Postnummer</Form.Label>
                  <Form.Control
                    type="text"
                    name="Postnummer"
                    value={this.state.Postnummer}
                    onChange={this.handleChange}
                    placeholder="Postnummer"
                  />
                </Form.Group>
                <Form.Group controlId="Postort">
                  <Form.Label>Postort</Form.Label>
                  <Form.Control
                    type="text"
                    name="Postort"
                    value={this.state.Postort}
                    onChange={this.handleChange}
                    placeholder="Postort"
                  />
                </Form.Group>{" "}
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Form.Check
                  className="CheckBox"
                  type="checkBox"
                  label="Villkor & Policy"
                  name="Villkor"
                  checked={this.state.Villkor}
                  onChange={this.toggle.bind(this)}
                  value="Villkor"
                  inline
                />
                <Form.Check
                  className="CheckBox"
                  type="checkBox"
                  label="Nyhetsbrev"
                  name="Nyhetsbrev"
                  id="1"
                  onChange={this.toggle.bind(this)}
                  checked={this.state.Nyhetsbrev}
                  inline
                />

                <Form.Group>
                  <Form.Control type="hidden" name="id" value={this.state.id} />
                  <br />
                  <Button variant="primary" type="submit">
                    Uppdatera
                  </Button>
                  <span className="result-text">{this.state.result}</span>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </React.Fragment>
    );
  }
  onFormSubmit(data) {
    this.saveKund(data);
  }
  saveKund = async (data) => {
    const res = await CallApi(
      "Put",
      `/kund/${data.id}`,
      data,
      await this.props.auth.getAccessTokenSilently(),
    );

    this.setState({
      result: res.data.message,
    });
  };
  async fetchFromApi(method, endpoint, body) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        {
          method,
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${await this.props.auth.getAccessTokenSilently()}`,
          },
        },
      );
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export default Profile;
