import React from "react";
import { Container } from "react-bootstrap";
import { LoadingSpinner } from "./components/LoadingSpinner";
import "./App.css";
import SearchKund from "./components/SearchKund";
import MyAccount from "./components/MyAccount";
import KundTable from "./components/KundTable";
import ManageDropoff from "./components/ManageDropoff";
import ManagePickup from "./components/ManagePickup";
import RegisterDropOff from "./components/RegisterDropOff";
import ManageReturns from "./components/ManageReturns";
import MyAccountAdmin from "./components/MyAccountAdmin";
import SelloPage from "./pages/SelloPage";
import PurchaseOrder from "./pages/PurchaseOrderPage";
import Pickup from "./components/Pickup";
import Profile from "./components/Profile";
import Nav from "./components/Header";
import Home from "./components/Home";
import ActivityPage from "./pages/ActivityPage";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import PrivateRoute from "./components/PrivateRoute";
import Callback from "./components/Callback";
import ManagePricelist from "./components/ManagePricelist";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { Router, Route, Redirect } from "react-router-dom";

const App = () => {
  const auth = useAuth0();
  let roles = [];
  if (auth.isAuthenticated) {
    roles = auth.user[process.env.REACT_APP_AUTH0_ROLES_URL];
    auth["roles"] = roles;
  }

  return (
    <Router history={history}>
      <React.Fragment>
        <Nav roles={roles} />
        <Container>
          {auth.isLoading && <LoadingSpinner />}
          {!auth.isLoading && (
            <div className="body">
              <Route
                path="/"
                exact
                render={(props) => <Home auth={auth} {...props} />}
              />
              <Route
                path="/callback"
                render={(props) => <Callback auth={auth} {...props} />}
              />
              <Route
                path="/search"
                exact
                render={(props) => <SearchKund auth={auth} {...props} />}
              />
              <Route
                path="/profile"
                render={(props) =>
                  auth.isAuthenticated ? (
                    <Profile auth={auth} {...props} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                path="/myaccount"
                render={(props) =>
                  auth.isAuthenticated ? (
                    <MyAccount auth={auth} {...props} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                path="/pickup"
                render={(props) =>
                  auth.isAuthenticated ? (
                    <Pickup auth={auth} {...props} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <PrivateRoute
                path="/managepickup"
                component={ManagePickup}
                roles={["Admin"]}
                auth={auth}
              />
              <PrivateRoute
                path="/customers"
                component={KundTable}
                roles={["Admin"]}
                auth={auth}
              />
              <PrivateRoute
                path="/dropoff"
                component={ManageDropoff}
                roles={["Admin"]}
                auth={auth}
              />
              <PrivateRoute
                path="/returns"
                component={ManageReturns}
                roles={["Admin"]}
                auth={auth}
              />
              <PrivateRoute
                path="/pricelist"
                component={ManagePricelist}
                roles={["Admin"]}
                auth={auth}
              />
              <PrivateRoute
                path="/myaccountadmin"
                component={MyAccountAdmin}
                roles={["Admin"]}
                auth={auth}
              />
              <PrivateRoute
                path="/sellosync"
                component={SelloPage}
                roles={["Admin"]}
                auth={auth}
              />
              <PrivateRoute
                path="/purchaseOrder"
                component={PurchaseOrder}
                roles={["Admin"]}
                auth={auth}
              />
              <Route
                path="/registerdropoff"
                render={(props) =>
                  auth.isAuthenticated ? (
                    <RegisterDropOff auth={auth} {...props} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <PrivateRoute
                path="/activity"
                component={ActivityPage}
                roles={["Admin"]}
                auth={auth}
              />
            </div>
          )}
        </Container>
      </React.Fragment>
    </Router>
  );
};
export default App;
