import React from "react";
import { Card, ListGroup, Row, Col } from "react-bootstrap";
import {
  ShoppingBag,
  Watch,
  Shirt,
  Headphones,
  CreditCard,
  ShoppingCart,
} from "lucide-react";

const transactions = [
  {
    icon: <ShoppingBag size={20} />,
    title: "Backpack",
    date: "2024-03-10",
    order: "#123456",
    amount: "$49.99",
  },
  {
    icon: <Watch size={20} />,
    title: "Smartwatch",
    date: "2024-03-09",
    order: "#123457",
    amount: "$99.99",
  },
  {
    icon: <Shirt size={20} />,
    title: "T-shirt",
    date: "2024-03-08",
    order: "#123458",
    amount: "$19.99",
  },
  {
    icon: <Headphones size={20} />,
    title: "Headphones",
    date: "2024-03-07",
    order: "#123459",
    amount: "$29.99",
  },
  {
    icon: <Headphones size={20} />,
    title: "Running Shoes",
    date: "2024-03-06",
    order: "#123460",
    amount: "$79.99",
  },
  {
    icon: <CreditCard size={20} />,
    title: "Payment Received",
    date: "2024-03-05",
    order: "#123461",
    amount: "$150.00",
  },
  {
    icon: <ShoppingCart size={20} />,
    title: "Items for Sale",
    date: "2024-03-04",
    order: "#123462",
    amount: "$199.99",
  },
];

const ActivityList = () => {
  return (
    <div className="call-to-action">
      <Card>
        <Card.Header>
          <Card.Title as="h2" className="text-center mb-0">
            Recent Activities
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-0">
          <ListGroup variant="flush">
            {transactions.map((transaction, index) => (
              <ListGroup.Item key={index} className="py-3">
                <Row className="align-items-center">
                  <Col xs={2} className="text-center">
                    <div className="bg-light rounded-circle p-2 d-inline-block">
                      {transaction.icon}
                    </div>
                  </Col>
                  <Col xs={7}>
                    <h6 className="mb-0">{transaction.title}</h6>
                    <small className="text-muted">
                      {transaction.date} | {transaction.order}
                    </small>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="fw-bold text-primary">
                      {transaction.amount}
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ActivityList;
