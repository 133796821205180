import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

function StartIcons() {
  return (
    <>
      <Row>
        <Col lg={4} xs={12}>
          <Link to="/myaccount">
            <span className="material-symbols-outlined start-icons mx-auto fade-in-bottom">
              wallet
            </span>
            <div className="text-center fade-in-bottom">SALDO</div>
          </Link>
        </Col>
        <Col lg={4} xs={12}>
          <Link to="/pickup">
            <span className="material-symbols-outlined start-icons mx-auto fade-in-bottom">
              local_shipping
            </span>

            <div className="text-center fade-in-bottom">LÄMNA IN</div>
          </Link>
        </Col>

        <Col lg={4} xs={12}>
          <Link to="/profile">
            <span className="material-symbols-outlined start-icons mx-auto  fade-in-bottom">
              manage_accounts
            </span>
            <div className="text-center fade-in-bottom">UPPGIFTER</div>{" "}
          </Link>
        </Col>
      </Row>
    </>
  );
}
export { StartIcons };
