import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import logo from "../footly-logo.png";
import Payment from "./Payment";
import Summary from "./Summary";
import Dropoffs from "./DropOffs";
import SoldInSello from "./SoldInSello";
import Earnings from "./Earnings";
import Purchases from "./Purchases";
import CallApi from "../components/CallApi";
import { LoadingSpinner } from "../components/LoadingSpinner";
import {
  getCustomerById,
  getSoldProductsForCustomer,
} from "../Helpers/CustomerInfo";

import { CalculateEarnings } from "../Helpers/CalculatePriceList";

export default class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      sumEarnings: "",
      sumEarningsWhenCostRemoved: "",
      accountNumber: "",
      balance: "",
      sumEarningsTotalWhenCostRemoved: [],
      showPayment: false,
      showSummary: false,
      showSoldInSello: false,
      showDropoffs: false,
      showEarnings: false,
      showError: false,
    };
  }
  handleClick = async (param) => {
    switch (param) {
      case "sold":
        this.setState({
          showSoldInSello: true,
          showDropoffs: false,
          showEarnings: false,
          showPurchases: false,
        });
        break;
      case "dropoffs":
        this.setState({
          showDropoffs: true,
          showSoldInSello: false,
          showEarnings: false,
          showPurchases: false,
        });
        break;
      case "earnings":
        this.setState({
          showEarnings: true,
          showDropoffs: false,
          showSoldInSello: false,
          showPurchases: false,
        });
        break;
      case "purchases":
        this.setState({
          showPurchases: true,
          showEarnings: false,
          showDropoffs: false,
          showSoldInSello: false,
        });
        break;
      default:
        break;
    }
  };

  getAlreadyPaid = async (kundid, token) => {
    var res = await CallApi("Get", `/payments/${kundid}`, null, token);
    var list = res.data.result.map(function (d) {
      return { year: new Date(d["paymentDate"]).getFullYear(), sum: d["sum"] };
    });

    let temp = list.reduce((result, item) => {
      if (result.hasOwnProperty(item.year)) {
        result[item.year] += item.sum;
      } else {
        result[item.year] = item.sum;
      }
      return result;
    }, {});
    var listOfYearsAndSum = Object.entries(temp).map(([year, sum]) => ({
      year,
      sum,
    }));
    return listOfYearsAndSum;
  };

  getData = async (id) => {
    var token = await this.props.auth.getAccessTokenSilently();
    var kundres = await getCustomerById(id, token);
    if (kundres.status !== 200) {
      this.setState({ showError: true });
      return;
    }
    const kundData = kundres.data.kund;
    const returnedProducts = kundData.ReturnedProducts;
    const products = await getSoldProductsForCustomer(kundData.Kundnr, token);

    const pricelist = kundData.Pricelist;
    const alreadyPaid = await this.getAlreadyPaid(kundData.id, token);
    const { sum, sumEarnings, soldItems, sumEarningsWhenCostRemoved } =
      await CalculateEarnings(
        pricelist,
        products.data.sold,
        returnedProducts,
        kundData.Kundtyp,
      );
    let dropOffNoItems = 0;
    let dropOffCost = 0;

    kundData.DropOffs.forEach((element) => {
      if (element.handledDate !== null) {
        if (element.paid !== true) {
          dropOffNoItems += element.noItems;
          dropOffCost +=
            element.pickupCost === null
              ? element.cost
              : element.cost + element.pickupCost;
        }
      }
    });
    let totalPurchasesSum = 0;
    let unpaidPurchases = [];
    if (kundData.Purchases.length > 0) {
      unpaidPurchases = kundData.Purchases.filter((row) => row.paid !== true);
      totalPurchasesSum = unpaidPurchases.reduce(
        (acc, row) => acc + row.totalSum,
        0,
      );
    }

    let paymentDate = new Date();
    let customerPaymentDate = paymentDate;
    var balance = sumEarnings - dropOffCost + totalPurchasesSum;

    const soldAndNotPaidInSello = products.data.sold.filter((product) => {
      return product.paid !== true;
    });

    this.setState({
      balance: balance > 0 ? balance : 0,
      droppoffs: kundData.DropOffs,
      costPerItem: kundData.Pricelist.CostPerItem,
      soldInSello: soldAndNotPaidInSello,
      sum,
      sumEarnings: sumEarnings > 0 ? sumEarnings : 0,
      sumEarningsWhenCostRemoved:
        sumEarningsWhenCostRemoved > 0 ? sumEarningsWhenCostRemoved : 0,
      sumEarningsTotalWhenCostRemoved: alreadyPaid,
      dropOffNoItems,
      dropOffCost,
      soldItems,
      customerFirstName: `${kundData.Förnamn}`,
      name: `${kundData.Förnamn} ${kundData.Efternamn}`,
      accountNumber: `${kundData.Clearingnr} ${kundData.Kontonummer}`,
      customerType: kundData.Kundtyp,
      kundnr: kundData.Kundnr,
      kundId: kundData.id,
      doljForsaljningspris: kundData.DoljForsaljningspris,
      returnedProducts,
      accessToken: token,
      paymentDate,
      customerPaymentDate,
      totalPurchasesSum,
      purchases: unpaidPurchases,
    });
  };

  getInlamningstillfallen = async () => {
    let Id = this.props.Id;
    const res = await CallApi(
      "Get",
      `/kund/${Id}`,
      "",
      await this.props.auth.getAccessTokenSilently(),
    );
    return res;
  };

  reload = (id, result, confirmation, moneyInAccount) => {
    this.setState({
      result,
      confirmation,
      moneyInAccount,
      showPayment: false,
      showResult: true,
    });
    this.getData(id);
  };
  async componentDidMount() {
    let id,
      email = "";
    if (typeof this.props.customerId !== "undefined") {
      id = this.props.customerId;
      email = this.props.customerEmail;
    } else {
      id = this.props.auth.user.sub.replace("auth0|", "");
      email = this.props.auth.user.email;
    }
    this.setState({ id, email, isLoading: true });
    await this.getData(id);
    this.setState({ isLoading: false });
  }

  render() {
    const { customerFirstName, showError } = this.state;
    return (
      <React.Fragment>
        <div className="call-to-action">
          <Row>
            <Col>{this.state.isLoading && <LoadingSpinner />}</Col>
          </Row>
          {this.state.isLoading === false && (
            <>
              <Row>
                <Col sm={8}>
                  <div className="intro">
                    <h2>Saldo</h2>
                    <p>
                      Hej {customerFirstName}!&nbsp;
                      <br />
                      Ditt saldo är{" "}
                      <span id="account-balance">
                        {Math.round(this.state.balance)}
                      </span>{" "}
                      kr sedan senaste utbetalningen.
                      <br />
                      {/* Ditt inlämningsnummer är: {this.state.kundnr}. */}
                    </p>
                    {showError === true && (
                      <p>
                        <b>
                          Något fick tyvärr fel vid hämtningen av uppgifter.
                          Försök igen eller kontakta oss.
                        </b>
                      </p>
                    )}
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="d-none d-md-block logo">
                    <img
                      alt="logo"
                      className="footly-img"
                      src={logo}
                      width="100%"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Container>
                  <div className="icon-div">
                    <Row>
                      <Col>
                        <span
                          className="material-symbols-outlined my-account-icon d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({
                              showPayment: true,
                              showSummary: false,
                              showDropoffs: false,
                              showSoldInSello: false,
                              showResult: false,
                            });
                          }}
                        >
                          wallet
                        </span>
                        <p className="d-flex justify-content-center align-items-center">
                          Utbetalning
                        </p>
                      </Col>
                      <Col>
                        <span
                          className="material-symbols-outlined my-account-icon d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({
                              showSummary: true,
                              showPayment: false,
                            });
                          }}
                        >
                          assignment
                        </span>

                        <p className="d-flex justify-content-center align-items-center">
                          Sammanställning
                        </p>
                      </Col>
                    </Row>
                    {this.state.showPayment && (
                      <Payment
                        accountNumber={this.state.accountNumber}
                        balance={this.state.balance}
                        email={this.state.email}
                        id={this.state.id}
                        accessToken={this.state.accessToken}
                        customerType={this.state.customerType}
                        customerNumber={this.state.kundnr}
                        name={this.state.name}
                        customerId={this.state.kundId}
                        reload={this.reload}
                        dropOffCost={this.state.dropOffCost}
                        paymentDate={this.state.paymentDate}
                        customerPaymentDate={this.state.customerPaymentDate}
                        soldInSello={this.state.soldInSello}
                        doljForsaljningspris={this.state.doljForsaljningspris}
                        purchases={this.state.purchases}
                      />
                    )}
                    {this.state.showPayment && this.state.balance < 1 && (
                      <>
                        <p>
                          <b>
                            Du måste ha över 0 kr i saldo för att kunna begära
                            en utbetalning.
                          </b>
                        </p>
                      </>
                    )}
                    {this.state.showSummary && (
                      <Summary {...this.state} onClick={this.handleClick} />
                    )}
                    {this.state.showSoldInSello && (
                      <SoldInSello
                        sold={this.state.soldInSello}
                        sum={this.state.sum}
                        kundtyp={this.state.customerType}
                        returnedProducts={this.state.returnedProducts}
                        doljForsaljningspris={this.state.doljForsaljningspris}
                      />
                    )}
                    {this.state.showDropoffs && (
                      <Dropoffs
                        dropoffs={this.state.droppoffs}
                        costPerItem={this.state.costPerItem}
                      />
                    )}
                    {this.state.showEarnings && (
                      <Earnings sum={this.state.sumEarnings} />
                    )}
                    {this.state.showPurchases && (
                      <Purchases purchases={this.state.purchases} />
                    )}
                  </div>
                  <Row>
                    <Col>
                      <h4>
                        {this.state.showResult && this.state.result}
                        <b>
                          {this.state.showResult && this.state.moneyInAccount}
                        </b>
                        {this.state.showResult && this.state.confirmation}
                      </h4>
                      <div id="previously-paid">
                        Du har tidigare fått utbetalt:{" "}
                        {this.state.sumEarningsTotalWhenCostRemoved.map(
                          (item) => (
                            <li key={item.year}>
                              {item.year}: {Math.round(item.sum)} kr.
                            </li>
                          ),
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Row>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}
